import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../../global";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import { productListGuide } from "./guide";
import { Autocomplete, TextField } from "@mui/material";
import moment from "moment";
// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No.", minWidth: 10 },
  { id: "companyName", label: "Company Name", minWidth: 40 },
  { id: "instrumentName", label: "Product Name", minWidth: 40 },
  { id: "rate", label: "Rate", minWidth: 40 },
];

function createData(id, instrumentId, rate) {
  return {
    id,
    instrumentId,
    rate,
  };
}

export default function ProductsList() {
  const productListGuides = productListGuide();
  const [page, setPage] = React.useState(0);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [productsList, setProductList] = React.useState([]);
  const [instrumentArray, setInstrumentArray] = React.useState([]);
  const [rowDaata, setRowData] = React.useState([]);
  const [pushIArray, setPushIArray] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitProductDelete = (id) => {
    let url = BASE_URL;
    axios
      .delete(url + `customProducts/${id}`)
      .then((res) => {
        toast("products deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const getProductList = (event) => {

    let url = BASE_URL;

    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey !== "") {
      let colName = {
        "Company Name": "c.companyName",
        "Product Name": "i.instrumentName",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    const payload1 = {
      query: `SELECT cp.*, i.instrumentName, c.companyName  FROM customProducts cp  LEFT JOIN clients c ON cp.clientId = c.id LEFT JOIN instruments i ON cp.instrumentId = i.id ${whereConditions} ORDER BY cp.id DESC`,
    };

    axios
      .post(url + "dynamic", payload1)
      .then((res) => {
        setProductList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getProductList();
  }, []);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      
      <div style={{display:"flex",justifyContent:"space-between"}}>            
      <Toolbar>
        <Button
        id="product_list_createbtn"
          variant="contained"
          size="small"
          component={Link}
          to="/master/product/createProduct"
        >
          <b>create PRODUCT</b>
        </Button>
      </Toolbar>
      <Tooltip title="User Guide" placement="top-start">
        <Button
         onClick={() => {
          productListGuides.drive();
         }}
        >
          <HelpIcon />
        </Button>
      </Tooltip>
    </div> 
    <br/>
    <div
        className="mb-2"
        style={{
          display: "flex",
          float: "left",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
      <div
        className="mb-2"
        style={{ display: "flex", float: "left", justifyContent: "flex-end", marginRight: "10px" }}
      >
        <br />

        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
            marginLeft: "10px",
          }}
          size="small"
          id="client_list_searchby" 
          options={[
            { key: "i.instrumentId", label: "Product Name" },
            { key: "c.companyName", label: "Company Name" },
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
          }}
        />
        <TextField
          id="client_list_searchbytext"
          label={"enter " + searchBy}
          size="small"
          variant="outlined"
          onChange={(e) => setSearchKey(e.target.value)}
          sx={{marginLeft:"10px"}}
        />
        <Button
        id="client_list_searchbtn"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          style={{ marginLeft: "10px" }}
          onClick={() => {
            getProductList();
          }}
        >
          <SearchIcon />
        </Button>
      </div>
      </div>
      <br />

      <Table id="product_list_table" stickyHeader aria-label="sticky table" size="small">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productsList
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    let value= row[column.id]

                    if (column.id == "id") {
                      value = page * rowsPerPage + index + 1;
                    }

                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}

                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <Tooltip title="Edit Product" placement="top-start">
                        <Button
                        id="product_list_edit"
                          style={{
                            borderColor: "#dc3545",
                          }}
                          component={Link}
                          to={`/master/editProduct/${row.id}`}
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete Product" placement="top-start">
                        <Button
                        id="product_list_delete"
                          onClick={(e) => {
                            if (
                              window.confirm("Really want to delete product?")
                            ) {
                              submitProductDelete(row.id);
                            }
                          }}
                          style={{
                            borderColor: "#dc3545",
                          }}
                        >
                          <DeleteIcon style={{ color: "#dc3545" }} />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={productsList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* <Table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="datatable-keytable"
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowDaata
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    let value = row[column.id];
                    if (column.id == "id") {
                      value = page * rowsPerPage + index + 1;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <Tooltip title="Edit Product" placement="top-start">
                        <Button
                          style={{
                            borderColor: "#dc3545",
                          }}
                          component={Link}
                          to={`/master/editProduct/${row.id}`}
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete Product" placement="top-start">
                        <Button
                          onClick={(e) => {
                            if (
                              window.confirm("Really want to delete product?")
                            ) {
                              submitProductDelete(row.id);
                            }
                          }}
                          style={{
                            borderColor: "#dc3545",
                          }}
                        >
                          <DeleteIcon style={{ color: "#dc3545" }} />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rowDaata.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      <ToastContainer />
    </TableContainer>
  );
}
