import { driver } from "driver.js";

export const departmentListGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this department list.",
        },
      },
      {
        element: "#department_list_createbtn",
        popover: {
          title: "Create New Department",
          description: "Click this button to create a new department.",
        },
      },
      {
        element: "#department_list_searchby",
        popover: {
          title: "Search Field",
          description: "Use this dropdown to search.",
        },
      },
      {
        element: "#department_list_searchbytext",
        popover: {
          title: "Enter Your Value",
          description:
            "When selecting a previous dropdown value, enter your desired search value.",
        },
      },
      {
        element: "#department_list_searchbtn",
        popover: {
          title: "Search",
          description:
            "After selecting a previous dropdown or entering a value, click this button to get results.",
        },
      },
      {
        element: "#department_list_table",
        popover: {
          title: "Department Table",
          description:
            "Table displaying department details where you can edit and delete departments.",
        },
      },
      {
        element: "#department_list_edit",
        popover: {
          title: "Edit Department",
          description: "Click this button to edit the department.",
        },
      },
      {
        element: "#department_list_delete",
        popover: {
          title: "Delete Department",
          description: "Click this button to delete the department.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can create departments.",
        },
      },
    ],
  });
};

export const createDepartmentGuide = () => {
    return driver({
      showProgress: true,
      steps: [
        {
          popover: {
            title: "👋 Let's get started...",
            description:
              "This tour will guide you through all sections and components in creating or editing a department.",
          },
        },
        {
          element: "#department_create_department",
          popover: {
            title: "Department",
            description: "Enter the department name.",
          },
        },
        {
          element: "#department_create_save",
          popover: {
            title: "Save Department",
            description: "Click this button to save the department.",
          },
        },
        {
          popover: {
            title: "Congratulations 🎉",
            description: "Now you can create or edit departments.",
          },
        },
      ],
    });
  };
  
