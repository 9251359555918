import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../global";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Grid, TextField, Autocomplete, useTheme, Box, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import SearchIcon from "@mui/icons-material/Search";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No.", minWidth: 40 },
  {
    id: "checkMark",
    label: "Payment Status",
    align: "center",
    minWidth: 10,
  },

  { id: "clientName", label: "Company Name", minWidth: 40 },
  {
    id: "address",
    label: "Address",
    align: "left",
    minWidth: 40,
  },
  {
    id: "contactNumber",
    label: "Contact Number",
    align: "left",
    minWidth: 40,
  },

  {
    id: "invoiceDate",
    label: "Invoice Date",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "invoiceNumber",
    label: "Invoice Number",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "subject",
    label: "Subject",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

function createData(
  id,
  checkMark,
  clientName,
  address,
  contactNumber,
  invoiceDate,
  invoiceNumber,
  subject
) {
  return {
    id,
    checkMark,
    clientName,
    address,
    contactNumber,
    invoiceDate,
    invoiceNumber,
    subject,
  };
}

export default function PaymentMasterList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [paymentData, setPaymentData] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);
  const [dateData, setDateData] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getData = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Company Name": "cl.companyName",
        "Invoice Number": "pm.invoiceNumber",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` pm.invoiceDate between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    if (whereConditions === "") {
      whereConditions = " where ";
    } else {
      whereConditions += " and ";
    }

    whereConditions += " pm.status = 1 ";

    let data = {
      query: `SELECT pm.id AS payment_id, pm.totalAmount AS payment_totalAmount,pm.paidAmount AS payment_paidAmount,  pm.address AS payment_address, pm.contactNumber AS payment_contactNumber, pm.conveyanceCharges AS payment_conveyanceCharges, pm.discount AS payment_discount, pm.gstType AS payment_gstType, pm.invoiceDate AS payment_invoiceDate, pm.invoiceNumber AS payment_invoiceNumber, pm.poNumber AS payment_poNumber, pm.subject AS payment_subject, cl.companyName AS client_companyName FROM paymentMaster AS pm LEFT JOIN clients AS cl ON pm.clientId = cl.id ${whereConditions} order by pm.id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `select count(pm.id) as no_of_rows FROM paymentMaster AS pm LEFT JOIN clients AS cl ON pm.clientId = cl.id ${whereConditions} order by pm.id DESC ${pagination_settings}`;
    return data;
  };

  const getPaymentList = (event) => {
    const data = getData();

    axios
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setDateData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function getTotalRows() {
    const data = getData("count");
    axios
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  const search = () => {
    getPaymentList();
  };

  const initiatInvoiceList = () => {
    var rowData = [];
    if (dateData.length) {
      for (let i = 0; i < dateData.length; i++) {
        rowData.push(
          createData(
            dateData[i].payment_id,
            dateData[i].payment_totalAmount -   dateData[i].payment_paidAmount <= 0 ? (
              <DoneRoundedIcon color="success" />
            ) : (
              <CloseRoundedIcon style={{ color: "red" }} />
            ),
            dateData[i].client_companyName,
            dateData[i].payment_address,
            dateData[i].payment_contactNumber,
            moment(
              dateData[i].payment_invoiceDate
                ? dateData[i].payment_invoiceDate
                : ""
            ).format("DD-MM-YYYY"),
            dateData[i].payment_invoiceNumber,
            dateData[i].payment_subject
          )
        );
      }
    }
    setPaymentData(rowData);
  };

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  useEffect(() => {
    initiatInvoiceList();
  }, [dateData]);

  useEffect(() => {
    // getPaymentList();
    getTotalRows();
  }, []);

  useEffect(()=>{
    getPaymentList();
  },[rowsPerPage, page])

  return (
    <>
      <div style={{ marginTop: "2em" }}>
      <br />
      <div
        className="mb-2"
        style={{ display: "flex", float: "right", justifyContent: "flex-end" }}
      >
        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} classNames="mr-2">
            <DatePicker
            slotProps={{ textField: { size: "small" ,fullWidth: true } }}
              label="From"
              value={from ? new Date(from) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setFrom(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </div>

        <div style={{ marginRight: "10px" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
            slotProps={{ textField: { size: "small" ,fullWidth: true } }}
              label="To"
              value={to ? new Date(to) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setTo(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
              style={{ marginRight: "20px" }}
            />
          </LocalizationProvider>
        </div>

        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
            marginLeft: "10px",
          }}
          size="small"
          id="combo-box-demo"
          options={[
            { key: "cl.companyName", label: "Company Name" },
            { key: "pm.invoiceNumber", label: "Invoice Number" },
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
          }}
        />
        <TextField
          id="outlined-basic"
          label={"enter " + searchBy}
          size="small"
          variant="outlined"
          onChange={(e) => setSearchKey(e.target.value)}
          sx={{marginLeft:"10px"}}
        />
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          style={{ marginLeft: "10px" }}
          onClick={() => {
            search();
          }}
        >
          <SearchIcon />
        </Button>
      
      </div>
      <br />
      </div>
      <br />
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <table
          stickyHeader
          aria-label="sticky table"
          size="small"
          id="datatable-keytable"
          width="100%"
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentData &&
              paymentData.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={index}
                    key={row.code}
                  >
                    {columns.map((column) => {
                      let value;
                      if (column.id === "contactNumber") {
                        value = (() => {
                          if (row[column.id]) {
                            const parsedData = row[column.id]
                              ? JSON.parse(row[column.id])?.[0]
                              : {};
                            return (
                              <>
                                <h6 style={{ fontSize: "1em" }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Name:
                                  </span>{" "}
                                  &nbsp;{parsedData?.contact}
                                </h6>
                                <h6 style={{ fontSize: "1em" }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Email:
                                  </span>{" "}
                                  &nbsp;{parsedData?.emailId}
                                </h6>
                                <h5 style={{ fontSize: "1em" }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Contact:
                                  </span>{" "}
                                  &nbsp;{parsedData?.contactPersonName}
                                </h5>
                              </>
                            );
                          }
                          return null;
                        })();
                      } else {
                        value = row[column.id];
                      }

                      if (column.id == "id") {
                        value = page * rowsPerPage + (index + 1);
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
               <TableCell>
                      <ButtonGroup size="small" aria-label="small button group">
                        <Tooltip title="Edit Payment" placement="top-start">
                          <Button
                            style={{
                              borderColor: "#dc3545",
                            }}
                            component={Link}
                            to={`/paymentmaster/editPayment/${row.id}`}
                          >
                            <PreviewIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Delete Payment" placement="top-start">
                          <Button
                            onClick={(e) => {
                              if (
                                window.confirm("Really want to delete payment?")
                              ) {
                                // submitSiteDelete(row.id);
                              }
                            }}
                            style={{
                              borderColor: "#dc3545",
                            }}
                          >
                            <DeleteIcon style={{ color: "#dc3545" }} />
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component="div"
          colSpan={3}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          ActionsComponent={TablePaginationActions}
        />
        <ToastContainer />
      </TableContainer>
    </>
  );
}
