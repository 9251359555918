import { driver } from "driver.js";
export const dataSheetListGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this datasheet List.",
        },
      },
      {
        element: "#datasheet-datefrom",
        popover: {
          title: "Select Date",
          description: "Select start date",
        },
      },
      {
        element: "#datasheet-dateto",
        popover: {
          title: "Select Date",
          description: "Select end date",
        },
      },
      {
        element: "#datasheet-searchby",
        popover: {
          title: "Search Field",
          description: "Using this dropdown, you can search",
        },
      },
      {
        element: "#datasheet-searchbytext",
        popover: {
          title: "Enter Your Value",
          description:
            "When you select a previous dropdown value, then enter your value which you want to search",
        },
      },
      {
        element: "#datasheet-searchbtn",
        popover: {
          title: "Search",
          description:
            "When you select a previous dropdown or enter a value, click on this button to get results",
        },
      },
      {
        element: "#datasheet-table",
        popover: {
          title: "SRF Table",
          description:
            "SRF Details Table where you can edit Datasheet and Download",
        },
      },
      {
        element: "#datasheet-editsrf",
        popover: {
          title: "Edit Datasheet Button",
          description: "Click this button to edit the datasheet",
        },
      },
      {
        element: "#datasheet-print",
        popover: {
          title: "Print Button",
          description: "Click this button to download the datasheet",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can edit the datasheet",
        },
      },
    ],
  });
};

export const viewdataSheetGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Lets get started...",
          description:
            "This tour will guide you through all sections and components in this Datasheet.",
        },
      },
      {
        element: "#datasheet-datasheetviewletterhead",
        popover: {
          title: "Select Letterhead",
          description: "Select letterhead with or without",
        },
      },
      {
        element: "#datasheet-datasheetview_electronicsign",
        popover: {
          title: "Select Electronic Sign",
          description:
            "Select electronic sign with or without.",
        },
      },
      {
        element: "#datasheet-datasheetviewDownalod",
        popover: {
          title: "Download datasheet",
          description:
            "Clicking this button allows you to download the datasheet.",
        },
      },
      {
        element: "#datasheet-datasheetview_print",
        popover: {
          title: "Datasheet Print",
          description: "",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can download datasheet",
        },
      },
    ],
  });
};

export const editdataSheetGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this edit Datasheet.",
        },
      },
      {
        element: "#datasheet-editdatasheet_firstdisabled",
        popover: {
          title: "Instrument Details",
          description: "These data are automatically fetched; this is disabled, and you can't edit this.",
        },
      },
      {
        element: "#datasheet-editdatasheet_dateofissue",
        popover: {
          title: "Date of Issue",
          description: "You have to select this date.",
        },
      },
      {
        element: "#datasheet-editdatasheet_datepo",
        popover: {
          title: "PO Date",
          description: "(This field is optional).",
        },
      },
      {
        element: "#datasheet-editdatasheet_dateofcallibration",
        popover: {
          title: "Date of Calibration",
          description: "You have to select this date.(this field is mandatory)",
        },
      },
      {
        element: "#datasheet-editdatasheet_dateofnextduedate",
        popover: {
          title: "Next Due Date",
          description: "This is automatically calculated based on the calibration date.",
        },
      },
      {
        element: "#datasheet-editdatasheet_dateofreceipt",
        popover: {
          title: "Receipt Date",
          description: "(This field is optional).",
        },
      },
      {
        element: "#datasheet-editdatasheet_dateofdc",
        popover: {
          title: "DC Date",
          description: "(This field is optional).",
        },
      },
      {
        element: "#datasheet-editdatasheet_seconddisabled",
        popover: {
          title: "Instrument Details",
          description: "These data are automatically fetched; this is disabled, and you can't edit this.",
        },
      },
      {
        element: "#datasheet-editdatasheet_starttemprature",
        popover: {
          title: "Start Temperature",
          description: "This field is mandatory; you have to enter this Start Temperature.(this field is mandatory)",
        },
      },
      {
        element: "#datasheet-editdatasheet_endtemprature",
        popover: {
          title: "End Temperature",
          description: "This field is optional.",
        },
      },
      {
        element: "#datasheet-editdatasheet_starthHumidity",
        popover: {
          title: "Start Humidity",
          description: "This field is mandatory; you have to enter this Start Humidity.(this field is mandatory)",
        },
      },
      {
        element: "#datasheet-editdatasheet_endhHumidity",
        popover: {
          title: "End Humidity",
          description: "This field is optional.",
        },
      },
      {
        element: "#datasheet-editdatasheet_atmospheric",
        popover: {
          title: "Atmospheric Pressure",
          description: "This field is optional.",
        },
      },
      {
        element: "#datasheet-editdatasheet_discipline",
        popover: {
          title: "Discipline",
          description: "This field is disabled; you can't change it.",
        },
      },
      {
        element: "#datasheet-editdatasheet_details_of_calibration",
        popover: {
          title: "Details of Calibration",
          description: "This table shows the details of Calibration.",
        },
      },
      {
        element: "#datasheet-editdatasheet_detailsreading",
        popover: {
          title: "Calibrate Instrument",
          description: "",
        },
      },
      {
        element: "#datasheet-editdatasheet_readingadd",
        popover: {
          title: "Add Reading",
          description: "Click this button; you can add reading.",
        },
      },
      {
        element: "#datasheet-editdatasheet_readingunit",
        popover: {
          title: "Add Unit",
          description: "Click this button; you can add a unit.",
        },
      },
      {
        element: "#datasheet-editdatasheet_readingheader",
        popover: {
          title: "Add Row Headings",
          description: "Click this button; you can add row headings.",
        },
      },
      {
        element: "#datasheet-editdatasheet_readingrow_copy",
        popover: {
          title: "Current Row Copy",
          description: "Click this button; you can copy this row with the master.",
        },
      },
      {
        element: "#datasheet-editdatasheet_readingrow_enterReading",
        popover: {
          title: "Enter Reading",
          description: "",
        },
      },
      {
        element: "#datasheet-editdatasheet_readingrow_selectmaster",
        popover: {
          title: "Select Master",
          description: "You can select a master for this reading row.",
        },
      },
      {
        element: "#datasheet-editdatasheet_readingrow_selectmasterrow",
        popover: {
          title: "Select Master",
          description: "You can select a master using this dropdown.(it show when master dropdown is open)",
        },
      },
      {
        element: "#datasheet-editdatasheet_readingrow_selectunit",
        popover: {
          title: "Select Unit",
          description: "",
        },
      },
      {
        element: "#datasheet-editdatasheet_readingrow_enterrrowheading",
        popover: {
          title: "Add Row Header",
          description: "This appears when a header is added.",
        },
      },
      {
        element: "#datasheet-editdatasheet_readingrowheader_delete",
        popover: {
          title: "Heading Row Delete",
          description: "Click this button; you can delete this row heading.(this appears when a header is added.)",
        },
      },  
      
      {
        element: "#datasheet-editdatasheet_readingrow_delete",
        popover: {
          title: "Reading Row Delete",
          description: "Click this button; you can delete this reading row.",
        },
      },
      {
        element: "#datasheet-editdatasheet_readingrowunit_delete",
        popover: {
          title: "Unit Row Delete",
          description: "Click this button; you can delete this unit row.",
        },
      },
      
      {
        element: "#datasheet-editdatasheet_readingtablehead_delete",
        popover: {
          title: "Instrument Delete",
          description: "Click this button; you can delete this table.",
        },
      },
      {
        element: "#datasheet-editdatasheet_readingtablehead_expanded_uncertainty",
        popover: {
          title: "Expanded Uncertainty",
          description: "When all readings are filled and the datasheet is saved, click this button; you can see Expanded Uncertainty.",
        },
      },
      {
        element: "#datasheet-editdatasheet_cms",
        popover: {
          title: "CMC Table(NABL Scope)",
          description: "This table shows NABL scope reading.",
        },
      },
      {
        element: "#datasheet-editdatasheet_opinionandanalysis",
        popover: {
          title: "Opinion And Analysis Table",
          description: "This table shows Opinion And Analysis.",
        },
      },
      {
        element: "#datasheet-editdatasheet_remarks",
        popover: {
          title: "Datasheet Remarks",
          description: "Enter remarks (This field is optional).",
        },
      },
      {
        element: "#datasheet-editdatasheet_calibratedby",
        popover: {
          title: "Datasheet Calibrated By",
          description: "Datasheet Calibrated By name is shown.",
        },
      },
      {
        element: "#datasheet-editdatasheet_issueno",
        popover: {
          title: "Issue Number",
          description: "Enter issue number (This field is optional).",
        },
      },
      {
        element: "#datasheet-editdatasheet_revno",
        popover: {
          title: "Revision Number",
          description: "You can't change this field.",
        },
      },
      {
        element: "#datasheet-editdatasheet_formno",
        popover: {
          title: "Form Number",
          description: "Enter form number (This field is optional).",
        },
      },
      {
        element: "#datasheet-editdatasheet_reviosiondate",
        popover: {
          title: "Revision Date",
          description: "This field is optional.",
        },
      },
      {
        element: "#datasheet-editdatasheet_savedatasheet",
        popover: {
          title: "Save Datasheet",
          description: "When your reading is filled, then you have to save this datasheet using this button.",
        },
      },
      {
        element: "#datasheet-editdatasheet_amendment",
        popover: {
          title: "Amendment History",
          description: "Amendment History",
        },
      },
      {
        element: "#datasheet-editdatasheet_navbar_certificate",
        popover: {
          title: "Certificate View/Download",
          description: "When the datasheet is calibration is done, then go to the certificate.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can calibrate the datasheet.",
        },
      },
    ],
  });
};

