import React, { useEffect, useState } from "react";

import { fetchCmcReadings } from "./fetchHandlers";
import { ClassicTable } from "../../../utils/components/Styles";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";

let readingColumns = [
  {
    field: "srNo",
    headerName: "Sr. No.",
  },
  {
    field: "fromRange",
    headerName: "From range",
    editable: true,
  },
  {
    field: "toRange",
    headerName: "To range",
    editable: true,
  },
  {
    field: "lowerCMC",
    headerName: "Lower CMC",
  },
  {
    field: "higherCMC",
    headerName: "Higher CMC",
  },
];

export default function CMCTable(props) {
  const { instrumentId } = props;

  const [cmcReadings, setCmcReadings] = useState([]);
  const [cmcReadingRows, setCmcReadingRows] = useState([]);

  const initiateCmcReadingRows = () => {
    let rows = [];
    let rowsSpace = [];
    for (let i = 0; i < cmcReadings.length; i++) {
      rowsSpace.push([null, null, null, null]);
      rows.push([
        cmcReadings[i].id,
        cmcReadings[i].fromRange,
        cmcReadings[i].toRange,
        cmcReadings[i].lowerCmc,
        cmcReadings[i].higherCmc,
      ]);

      setCmcReadingRows(rows);
    }
  };

  useEffect(() => {
    initiateCmcReadingRows();
  }, [cmcReadings]);

  useEffect(() => {
    fetchCmcReadings(instrumentId, setCmcReadings);
  }, [instrumentId]);

  if (cmcReadingRows.length < 1) return <></>;

  return (
    <div id="datasheet-editdatasheet_cms">
      <h4 style={{ "margin-bottom": "0px" }}>CMC</h4>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns.map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {cmcReadingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    if (cellIndex > 0) {
                      return (
                        <TableCell>{cell?.replaceAll("#", " ")}</TableCell>
                      );
                    }
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>

        <br />
      </div>
    </div>
  );
}
