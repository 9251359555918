
import { BASE_URL } from "../../global";
import axios from "axios";

export async function allSettings(clientID) {
    try {
        const generalSettingsPromise = axios
            .get(
                BASE_URL + `settings?_where=(status,ne,-1)`
            )
            .then((res) => res.data)
            .catch((err) => {
                console.log("setting fetch error : ", err);
                return [];
            });

        let clientSetting = [];
        if (clientID) {
            clientSetting = await axios
                .get(
                    BASE_URL + `clientSettings?_where=(status,ne,-1)~and(clientId,eq,${clientID})`
                )
                .then((res) => res.data)
                .catch((err) => {
                    console.log("setting fetch error : ", err);
                    return [];
                });
        }

        const [generalSettings, clientSettings] = await Promise.all([generalSettingsPromise, clientSetting]);

        const mergeSettings = (general, client) => {
            const settingsMap = new Map();

            // Add general settings to map
            general.forEach(setting => {
                settingsMap.set(setting.keyName, setting);
            });

            // Update map with client settings, overriding if keyName exists
            client.forEach(setting => {
                settingsMap.set(setting.keyName, setting);
            });

            // Return combined array without duplicates based on keyName
            return Array.from(settingsMap.values());
        };

        // Merge the arrays
        const combinedSettings = mergeSettings(generalSettings, clientSettings);
        return combinedSettings;
    } catch (error) {
        console.error("Error in allSettings:", error);
        return [];
    }
}
