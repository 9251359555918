import React, { useEffect, useRef } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Autocomplete,
  TableContainer,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Container,
  Divider,
  Radio,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ClassicTable } from "../../../utils/components/Styles";
import IconButton from "@mui/material/IconButton";
import { object } from "prop-types";
import { editClientGuide } from "./guide";
import MultiAddressInput, { onSubmitAddress } from "./multiAddressInput";
import { allSettings } from "../../../utils/components/allSettings";
const axios = require("axios");

const TableCellCss = {
  padding: "10px 20px",
  borderBottom:"none",
  border: "1px solid gray",
};

export default function UpdatreClient(props) {
  const editClientGuides = editClientGuide();
  const [companyName, setCompanyNanme] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [gstNumber, setGstNumber] = React.useState("");
  const [addressIds, setAddressIds] = React.useState("");
  const [state, setState] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [shippingAddressIds, setShippingAddressIds] = React.useState("");
  const [contactDetailsRows, setContactDetailsRowsRows] = React.useState([
    ["", "", ""],
  ]);
  const [calibratedByChecked, setCalibratedByChecked] = React.useState("false");
  const [checkedByChecked, setCheckedByChecked] = React.useState("false");
  const [approvedByChecked, setApprovedByChecked] = React.useState("false");
  const [reviewedByChecked, setReviewedByChecked] = React.useState("false");
  const [signedByChecked, setSignedByChecked] = React.useState("false");
  const [branches, setBranches] = React.useState([]);
  const [selectedBranch, setSelectedBranch] = React.useState(null);

  const [allRole_PossibleFields, setAllRole_PossibleFields] = React.useState(
    {}
  );
  const [selectedRolesValue, setSelectedRolesValue] = React.useState({
    role1: { keyName: "role1", type: 2, value: "false", status: 1 },
    role2: { keyName: "role2", type: 2, value: "false", status: 1 },
    role3: { keyName: "role3", type: 2, value: "false", status: 1 },
    role4: { keyName: "role4", type: 2, value: "false", status: 1 },
    role5: { keyName: "role5", type: 2, value: "false", status: 1 },
    others: [],
  });

  const [allSettingsArray, setAllSettingsArray] = React.useState([]);
  const [currentClientID, setCurrentClientID] = React.useState(null);
  const [allAddresses, setAllAddresses] = React.useState([]);
  const [isSettingSection, setIsSettingSection] = React.useState(false);

  const params = useParams();
  const childRef = useRef(null);

  const readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
      },
      {
        field: "address",
        headerName: "Address",
        editable: true,
      },
      {
        field: "contactPersonName",
        headerName: "Contact Person Name",
        editable: true,
      },

      {
        field: "contactNo",
        headerName: "Contact No",
        editable: true,
      },
      {
        field: "email",
        headerName: "email Id",
        editable: true,
      },
    ],
  ];

  const getBranches = (setBranch) => {
    axios.get(BASE_URL + "branch?_where=(status,eq,1)").then((res) => {
      setBranch(res.data);
      setBranches(
        res.data.map((item) => {
          return {
            id: item.id,
            branchName: item.branchName,
          };
        })
      );
    });
  };

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...contactDetailsRows];
    newRows[rowIndex][colIndex] = value;
    setContactDetailsRowsRows([...newRows]);
  };

  const addRangeRow = () => {
    let newReadingRows = [...contactDetailsRows];
    newReadingRows.push(["", "", "", ""]);
    setContactDetailsRowsRows([...newReadingRows]);
  };

  const deleteReadingRow = (rowIndex) => {
    let newReadingRows = [...contactDetailsRows];
    if (newReadingRows[rowIndex] != undefined) {
      newReadingRows.splice(rowIndex, 1);
      setContactDetailsRowsRows([...newReadingRows]);
    }
  };

  const initiateChData = () => {
    try {
      let rows = [];
      var contactDetailsArray = [];
      if (contact != "") {
        contactDetailsArray = JSON.parse(contact);
      }
      for (let i = 0; i < contactDetailsArray.length; i++) {
        rows.push([
          contactDetailsArray[i].contact,
          contactDetailsArray[i].contactPersonName,
          contactDetailsArray[i].emailId,
        ]);
      }
      setContactDetailsRowsRows(rows);
    } catch (error) {
      console.log(error);
    }
  };

  const getClientsList = () => {
    let url = BASE_URL;
    axios
      .get(url + `clients/${params.id}`)
      .then((res) => {
        let newData = res.data[0];
        setCompanyNanme(newData?.companyName);
        setContact(newData?.contact);
        setAddressIds(newData?.address);
        setShippingAddressIds(newData?.shippingAddress);
        setGstNumber(newData?.gstNumber);
        setState(newData?.state);
        setEmail(newData?.email);
        // setSignature(newData?.signature);
        getBranches((inpt) => {
          let tmp = {};
          inpt.map((e) => (tmp[e.id] = e));
          newData?.branch
            ? setSelectedBranch(tmp[newData?.branch] || null)
            : setSelectedBranch(null);
        });
        let rows = [];
        var contactDetailsArray = [];
        if (newData?.contact != "" && newData?.contact != null) {
          contactDetailsArray = JSON.parse(newData?.contact);
        }
        for (let i = 0; i < contactDetailsArray.length; i++) {
          rows.push([
            contactDetailsArray[i].address,
            contactDetailsArray[i].contactPersonName,
            contactDetailsArray[i].contact,
            contactDetailsArray[i].emailId,
          ]);
        }
        setContactDetailsRowsRows(rows);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const onSubmit = async () => {
    try {
      const AllIds = await onSubmitAddress(
        allAddresses,
        setShippingAddressIds,
        setAddressIds
      );

      const rows = contactDetailsRows.map((row) => ({
        address: row[0] || "",
        contactPersonName: row[1] || "",
        contact: row[2] || "",
        emailId: row[3] || "",
      }));

      const checkedObj = {
        forOrganization: {
          calibratedBy: calibratedByChecked.toString(),
          checkedBy: checkedByChecked.toString(),
          approvedBy: approvedByChecked.toString(),
        },
        forClient: {
          reviewedBy: reviewedByChecked.toString(),
          signedBy: signedByChecked.toString(),
        },
      };

      const jsonData = {
        companyName,
        contact: rows.length ? JSON.stringify(rows) : null,
        address:
          addressIds && AllIds?.[0]
            ? `${addressIds},${AllIds?.[0]}`
            : addressIds || AllIds?.[0] || "",
        shippingAddress:
          shippingAddressIds && AllIds?.[1]
            ? `${shippingAddressIds},${AllIds?.[1]}`
            : shippingAddressIds || AllIds?.[1] || "",
        gstNumber,
        state,
        email,
        signature: JSON.stringify(checkedObj),
        branch: selectedBranch ? `${selectedBranch?.id}` : null,
      };

      const url = BASE_URL;

      const axiosCall = params.id
        ? axios.patch(url + `clients/${params.id}`, jsonData)
        : axios.post(url + `clients`, jsonData);

      await axiosCall;

      if (params.id) {
        const sqlQuery = {
          query: `DELETE FROM clientSettings WHERE clientId=${params.id}`,
        };
        await axios.post(BASE_URL + `dynamic`, sqlQuery);
      }

      await axios.post(url + "clientSettings/bulk", allSettingsArray);

      toast.success(params.id ? "Clients updated!" : "Client Created!");
    } catch (error) {
      toast.error(error.message || "Something Went Wrong!");
    } finally {
      props.setLoader(false);
    }
  };

  useEffect(async () => {
    const AllSettings = await allSettings();
    let SettingValue = AllSettings.find(
      (ele) => ele.keyName === "Client Settings"
    )?.value;
    if (SettingValue === "true") {
      setIsSettingSection(true);
    }

    let rolesToCheck = [
      "role1_PossibleFields",
      "role2_PossibleFields",
      "role3_PossibleFields",
      "role4_PossibleFields",
      "role5_PossibleFields",
      "others_PossibleFields",
      // "role6_PossibleFields",
    ];

    let allRolesPossibleFields = AllSettings.filter((ele) => {
      return rolesToCheck.some((role) => ele.keyName.includes(role));
    });

    // Remove id and lastModified from each object
    let cleanedData = allRolesPossibleFields.map(
      ({ id, lastModified, ...rest }) => rest
    );

    // Organize data by keyName
    let organizedData = {};

    cleanedData.forEach(({ keyName, value, type, status }) => {
      let labels = value.split(",").map((label) => ({
        label,
        keyName: keyName.split("_")[0],
        type,
        value: "false",
        status,
      }));
      if (!organizedData[keyName]) {
        organizedData[keyName] = [];
      }
      organizedData[keyName].push(...labels);
    });

    // Handling other keyName values
    let otherKeyName = "others_PossibleFields";
    let otherData = organizedData[otherKeyName] || [];

    delete organizedData[otherKeyName]; // Remove others key from organizedData

    // Modify objects in otherData to match the desired structure
    let modifiedOtherData = otherData.map(({ label, type, status }) => ({
      label,
      keyName: label,
      type,
      value: "false",
      status,
    }));

    // Add modifiedOtherData back to organizedData under otherKeyName
    organizedData[otherKeyName] = modifiedOtherData;

    setAllRole_PossibleFields(organizedData);
    setSelectedRolesValue((prevState) => ({
      ...prevState,
      ["others"]: organizedData?.others_PossibleFields || [],
    }));
  }, []);
  useEffect(() => {
    if (params.id) getClientsList();
    initiateChData();
    if (!params.id) getBranches(() => {});
  }, []);

  const AllClientSettings = () => {
    const handleChange = (event, label) => {
      setSelectedRolesValue((prevState) => ({
        ...prevState,
        ["others"]: prevState["others"].map((item) => {
          if (item.keyName === label) {
            return {
              ...item,
              value: item.value === "true" ? "false" : "true", // Toggling the value
            };
          }
          return item;
        }),
      }));
    };
    const handleChangeForRoles = (event, item) => {
 
      const { keyName } = item;
      const newValue = event.target.checked ?  item.label : "false" ;

      setSelectedRolesValue((prevState) => ({
        ...prevState,
        [keyName]: { ...prevState[keyName], value: newValue },
      }));
    };

    const findCurrentClientID = () => {
      if (!params.id) {
        axios
          .get(BASE_URL + "clients?_fields=id&_sort=-id&_size=1")
          .then((res) => {
            let idIs = res?.data[0]?.id + 1;
            setCurrentClientID(idIs);
          });
      } else {
        setCurrentClientID(parseInt(params.id));
      }
    };

    useEffect(() => {
      const allObjects = Object.values(selectedRolesValue).map((obj) => {
        if (Array.isArray(obj)) {
          return Object.values(obj);
        }
        return obj;
      });

      const flattenedArray = allObjects.flat().map((obj) => {
        if (obj.label) {
          const { label, ...rest } = obj;
          return { ...rest, clientId: currentClientID };
        }
        return { ...obj, clientId: currentClientID };
      });
      setAllSettingsArray(flattenedArray);
    }, [selectedRolesValue, currentClientID]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const sqlQuery = {
            query: `SELECT keyName, value FROM clientSettings WHERE clientId = ${params.id}`,
          };

          const res = await axios.post(BASE_URL + `dynamic`, sqlQuery);
          const fetchedData = res.data;

          setSelectedRolesValue((prevState) => {
            const updatedRoles = { ...prevState };

            fetchedData.forEach((item) => {
              const { keyName, value } = item;

              if (keyName.includes("role") && updatedRoles[keyName]) {
                updatedRoles[keyName].value = value;
              } else {
                const otherIndex = updatedRoles.others.findIndex(
                  (obj) => obj.keyName === keyName
                );

                if (otherIndex !== -1) {
                  updatedRoles.others[otherIndex].value = value;
                }
              }
            });

            return updatedRoles;
          });
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    }, [params.id,allRole_PossibleFields]);

    useEffect(() => {
      findCurrentClientID();
    }, []);

    return (
      <>
        {isSettingSection && (
        <Box id="client_edit_extrasetting">
          <Typography variant="h6" gutterBottom align="left" sx={{ margin: "1.5rem 0px" }}>
            Settings
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2} style={TableCellCss}>
                  <Typography variant="paragraph" ><b>
                    Organization (For Signature) </b></Typography>
                  </TableCell>
                </TableRow>
                {/* Role 1 */}
                <TableRow>
                  <TableCell style={TableCellCss}>
                    <Typography variant="paragraph">Role1:</Typography>
                  </TableCell>
                  <TableCell style={TableCellCss}>
                      {allRole_PossibleFields?.role1_PossibleFields?.map((item, index) => (
                        <FormControlLabel
                        style={{height:"20px",margin:"3px"}}
                          key={index}
                          control={
                            <Checkbox
                              checked={
                                selectedRolesValue?.role1 &&
                                selectedRolesValue?.role1?.value === item?.label
                              }
                              onChange={(event) => handleChangeForRoles(event, item)}
                            />
                          }
                          label={<Typography variant="caption">{item.label}</Typography>}
                        />
                      ))}
                  </TableCell>
                </TableRow>
    
                {/* Role 2 */}
                <TableRow>
                  <TableCell style={TableCellCss}>
                    <Typography variant="paragraph">Role2:</Typography>
                  </TableCell>
                  <TableCell style={TableCellCss}>
                      {allRole_PossibleFields?.role2_PossibleFields?.map((item, index) => (
                        <FormControlLabel
                        style={{height:"20px",margin:"3px"}}
                          key={index}
                          control={
                            <Checkbox
                              checked={
                                selectedRolesValue?.role2 &&
                                selectedRolesValue?.role2?.value === item.label
                              }
                              onChange={(event) => handleChangeForRoles(event, item)}
                            />
                          }
                          label={<Typography variant="caption">{item.label}</Typography>}
                        />
                      ))}
                  </TableCell>
                </TableRow>

                {allRole_PossibleFields?.role3_PossibleFields || allRole_PossibleFields?.role4_PossibleFields || allRole_PossibleFields?.role5_PossibleFields ? (
                <TableRow>
                  <TableCell colSpan={2} style={TableCellCss}>
                    <Typography variant="paragraph" ><b>Clients (For Signature)</b></Typography>
                  </TableCell>
                </TableRow>
              ) : null}

    
                {/* Role 3 */}
                {allRole_PossibleFields?.role3_PossibleFields && 
                <TableRow>
                  <TableCell style={TableCellCss}>
                    <Typography variant="paragraph">Role3:</Typography>
                  </TableCell>
                  <TableCell style={TableCellCss}>
                      {allRole_PossibleFields?.role3_PossibleFields?.map((item, index) => (
                        <FormControlLabel
                        style={{height:"20px",margin:"3px"}}
                          key={index}
                          control={
                            <Checkbox
                              checked={
                                selectedRolesValue?.role3 &&
                                selectedRolesValue?.role3?.value === item?.label
                              }
                              onChange={(event) => handleChangeForRoles(event, item)}
                            />
                          }
                          label={<Typography variant="caption">{item.label}</Typography>}
                        />
                      ))}
                  </TableCell>
                </TableRow>
                }
    
                {/* Role 4 */}
                {allRole_PossibleFields?.role4_PossibleFields && 
                <TableRow>
                  <TableCell style={TableCellCss}>
                    <Typography variant="paragraph">Role4:</Typography>
                  </TableCell>
                  <TableCell style={TableCellCss}>
                      {allRole_PossibleFields?.role4_PossibleFields?.map((item, index) => (
                        <FormControlLabel
                        style={{height:"20px",margin:"3px"}}
                          key={index}
                          control={
                            <Checkbox
                              checked={
                                selectedRolesValue?.role4 &&
                                selectedRolesValue?.role4?.value === item.label
                              }
                              onChange={(event) => handleChangeForRoles(event, item)}
                            />
                          }
                          label={<Typography variant="caption">{item.label}</Typography>}
                        />
                      ))}
                  </TableCell>
                </TableRow>
                }
    
                {/* Role 5 */}
                {allRole_PossibleFields?.role5_PossibleFields && 
                <TableRow>
                  <TableCell style={TableCellCss}>
                    <Typography variant="paragraph">Role5:</Typography>
                  </TableCell>
                  <TableCell style={TableCellCss}>
                      {allRole_PossibleFields?.role5_PossibleFields?.map((item, index) => (
                        <FormControlLabel
                        style={{height:"20px",margin:"3px"}}
                          key={index}
                          control={
                            <Checkbox
                              checked={
                                selectedRolesValue?.role5 &&
                                selectedRolesValue?.role5?.value === item.label
                              }
                              onChange={(event) => handleChangeForRoles(event, item)}
                            />
                          }
                          label={<Typography variant="caption">{item.label}</Typography>}
                        />
                      ))}
                  </TableCell>
                </TableRow>
                }
    
                {/* Miscellaneous */}
                {allRole_PossibleFields?.others_PossibleFields && allRole_PossibleFields?.others_PossibleFields?.length > 0 && (
                  <>
                <TableRow>
                  <TableCell colSpan={2} style={TableCellCss}>
                  <Typography variant="paragraph" ><b>
                  Miscellaneous</b></Typography>
                  </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={TableCellCss}>
                    <Typography variant="paragraph">Other:</Typography>
                    </TableCell>
                  <TableCell style={TableCellCss}>
                      {allRole_PossibleFields?.others_PossibleFields?.map((item, index) => (
                        <FormControlLabel
                        style={{height:"20px",margin:"3px"}}
                          key={index}
                          control={
                            <Checkbox
                              checked={
                                selectedRolesValue?.others &&
                                selectedRolesValue?.others?.find(
                                  (ele) => ele.label === item.label
                                )?.value === "true"
                              }
                              onChange={(event) => handleChange(event, item.label)}
                              name={item.label}
                            />
                          }
                          label={<Typography variant="caption">{item.label}</Typography>}
                        />
                      ))}
                  </TableCell>
                </TableRow>
                  </>
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        )}
      </>
    );
  };

  return (
    <Container sx={{ mt: 2, p: 2 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          Edit Client
        </Typography>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              editClientGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="client_edit_compname"
            label="Company Name *"
            size="small"
            fullWidth
            variant="outlined"
            value={companyName}
            onChange={(e) => setCompanyNanme(e.target.value)}
          />
        </Grid>
        {/* <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Billing Address"
            size="small"
            fullWidth
            variant="outlined"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>{" "} */}
        <Grid item xs={3}>
          <Autocomplete
            fullWidth
            id="client_edit_branch"
            size="small"
            options={branches || []}
            defaultValue={undefined}
            value={selectedBranch || null}
            disableCloseOnSelect
            getOptionLabel={(option) => option?.branchName || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Branch"
                placeholder="Select Branch"
              />
            )}
            onChange={(event, value) => setSelectedBranch(value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="client_edit_GST"
            label="GST Number *"
            size="small"
            fullWidth
            variant="outlined"
            value={gstNumber}
            onChange={(e) => setGstNumber(e.target.value)}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <TextField
            id="client_edit_State"
            label="State *"
            size="small"
            fullWidth
            variant="outlined"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
        </Grid>
        {/* <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Email *"
            size="small"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid> */}
        <Grid item xs={12}>
          <MultiAddressInput
            addressIds={addressIds}
            shippingAddressIds={shippingAddressIds}
            setAddressIds={setAddressIds}
            setShippingAddressIds={setShippingAddressIds}
            allAddresses={allAddresses}
            setAllAddresses={setAllAddresses}
            ref={childRef}
          />
        </Grid>
      </Grid>
      <br />
      <Divider
        sx={{
          backgroundColor: "black",
          height: "2px",
        }}
      />
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h6" gutterBottom
          style={{ float: "left", margin: "20px 0px" }}
        >
          Contact Details
        </Typography>
        <Button
          id="client_edit_addcontact"
          variant="contained"
          size="small"
          onClick={(e) => {
            addRangeRow(0);
          }}
        >
          Add Contact
        </Button>
      </Box>

      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contactDetailsRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => (
                    <TableCell key={cellIndex}>
                      {cellIndex === 0 ? (
                        <Autocomplete
                          id="client_edit_contvalue"
                          options={allAddresses}
                          size="small"
                          getOptionLabel={(option) => option?.address}
                          value={cell !== null ? { address: cell } : null}
                          onChange={(event, value) => {
                            let optionId = value?.address;
                            updateCellValue(index, cellIndex, optionId);
                          }}
                          sx={{ width: "320px" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Address *"
                              variant="outlined"
                            />
                          )}
                        />
                      ) : (
                        <TextField
                          id="client_edit_contvalue"
                          size="small"
                          value={cell}
                          onChange={(e) => {
                            updateCellValue(index, cellIndex, e.target.value);
                          }}
                        />
                      )}
                    </TableCell>
                  ))}
                  <TableCell>
                    <DeleteIcon
                      id="client_edit_contdelte"
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        deleteReadingRow(0, index);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
        <br />
      </div>

      <Divider
        sx={{
          backgroundColor: "black",
          height: "2px",
          margin: "5px 0px",
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>{AllClientSettings()}</Box>
        </Grid>
      </Grid>

      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "3rem",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Button
          id="client_edit_save"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          {params.id ? "Update" : "Save"}
        </Button>
      </Toolbar>
      <ToastContainer />
    </Container>
  );
}
