import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import html2pdf from "html2pdf.js";
import UnitechHeader from "../image/certificate_header.png";
import UnitechFooter from "../image/certificate_footer.png";
import { toPng } from "html-to-image";
import moment from "moment";
import { countOfCertificatesAnalytics } from "../../utils/analytics/certificateAnalytics";
import ViewCertificate from "./viewCertificate";
import { makeChunks } from "../helper/helpers";
import { useGetRoleInfo } from "../login/Login";

export default function MultipleCertificate() {
  const printComponentRef = React.useRef();
  const [letterHead, setLetterHead] = React.useState(true);
  const [electronicSign, setElectronicSign] = React.useState(true);
  let { certificateIds } = useParams();
  const certificateIdsArray = certificateIds.split(",").map(Number);
  const { roles } = useGetRoleInfo(true);

  const generateDynamicHeader = async (
    ulrNumber,
    certificateNumber,
    pageNo,
    totalPages
  ) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `    
    <div id="responsivepdfheader">
      <table  style="width:100%;">
        <tr>
          ${
            ulrNumber
              ? `<td style="padding:5px;font-family: Calibri, sans-serif;height:60px;">
            <b style="font-size:17px;">ULR No : </b> 
            <b style="font-size:16px;">${ulrNumber}</b>
          </td>`
              : ""
          }
          <td style="padding:5px;font-family: Calibri, sans-serif;height:60px;">
            <b style="font-size:17px;">Certificate No : </b>
            <b style="font-size:16px;">${certificateNumber}</b>
          </td>
          <td style="padding:5px;font-family: Calibri, sans-serif;height:60px;">
            <b style="font-size:17px;">Page Number : </b>
            <b style="font-size:16px;">${pageRange}</b>
          </td>
        </tr>
      </table>
    </div>
    `;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechHeader} style="width:100%; height:170px;"/>
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generateDynamicFooter = async (
    calibratedby,
    calibrationDate,
    approvedby,
    approvedDate,
    reviewedBy,
    reviewedDate,
    clientId,
    signedBy,
    signedDate,
    witnessedBy,
    witnessDate
  ) => {
    let footers = [...roles];
    footers.map((e, i) => {
      if (e.id == 1) {
        footers[i].by = calibratedby && calibratedby.length >= 2 ? calibratedby[1] : '';
        footers[i].date = calibrationDate;
        footers[i].companyName = calibratedby && calibratedby.length >= 4 ? calibratedby[3] : '';
        footers[i].label = "Calibrated By";
      } else if (e.id == 2) {
        footers[i].by = approvedby && approvedby.length >= 2 ? approvedby[1] : '';
        footers[i].date = approvedDate;
        footers[i].companyName = approvedby && approvedby.length >= 4 ? approvedby[3] : '';
        footers[i].label = "Checked And Approved By";
      } else if (e.id == 3) {
        footers[i].by = reviewedBy && reviewedBy.length >= 2 ? reviewedBy[1] : '';
        footers[i].date = reviewedDate;
        footers[i].companyName = reviewedBy && reviewedBy.length >= 4 ? reviewedBy[3] : '';
        footers[i].label = "Reviewed By";
        footers[i].name = clientId;
      } else if (e.id == 4) {
        footers[i].by = signedBy && signedBy.length >= 2 ? signedBy[1] : '';
        footers[i].date = signedDate;
        footers[i].companyName = signedBy && signedBy.length >= 4 ? signedBy[3] : '';
      } else if (e.id == 5) {
        footers[i].by = witnessedBy && witnessedBy.length >= 2 ? witnessedBy[1] : '';
        footers[i].date = witnessDate;
        footers[i].companyName = witnessedBy && witnessedBy.length >= 4 ? witnessedBy[3] : '';
      }
    });
    footers = makeChunks(footers, footers.length == 4 ? 2 : 3);
    footers.map((row, i) => {
      footers[i][0].align = "left";
      if (row.length === 3) {
        footers[i][1].align = "center";
      }
      footers[i][row.length - 1].align = "right";
    });
    let footerString = `
    <div id="responsivepdfheader">
      ${footers
        .map(
          (row) => `
          <table style="width:100%;margin-bottom:5px">
          <tr>
          <td colspan="2">
          <b style="font-size:23px;">
          <b style="text-transform: 'uppercase'">For BFI, Mumbai</b> 
          </b>
          </td>
          <td>
          <b style="font-size:23px;">
          <b style="text-transform: 'uppercase'">For Customer</b> 
          </b>
          </td>
          </tr>
          <tr>
            ${row
              .map((column) =>
                electronicSign
                  ? `
                  <td style="vertical-align:top;text-align: ${
                    column.align
                  };font-family: Calibri, sans-serif;line-height:30px;width:${
                      100 / row.length
                    }%">
                    <div style="width: 100%;disply:flex;flex-direction:column;">
                    <div style="border-bottom:1px solid black;padding:5px 15px;">
                    <b style="font-size:20px;">
                    <b style="text-transform: 'uppercase'">${column.label}</b> 
                    </b>
                    </div>
                    <div style="border-bottom:1px solid black;padding:5px 15px;">
                    <b style="font-size:20px;">
                    ${column.by ? "Electronically signed by" : "\u00A0"}
                        </b>
                        <br>
                          <b style="font-size:19px;">${
                            column.by ? column.by : "\u00A0"
                          }
                            <br>
                            ${
                              column.date
                                ? moment(column.date).format(
                                    "DD/MM/yyyy hh:mm A"
                                  )
                                : "\u00A0"
                            }
                        </b>
                    </div>
                    <div style="padding:5px 15px;">
                    <b style="font-size:20px;">
                    <b style="text-transform: 'uppercase'">${column.name}</b> 
                    </b>
                    </div>
                    </div>    
                  </td>`
                  : `
                  <td style="vertical-align:top;text-align: ${
                    column.align
                  };font-family: Calibri, sans-serif;line-height:30px;width:${
                      100 / row.length
                    }%">
                    <div style="width: 100%;disply:flex;flex-direction:column;">
                    <div style="border-bottom:1px solid black;padding:5px 15px;">
                    <b style="font-size:20px;">
                    <b style="text-transform: 'uppercase'">${column.label}</b> 
                    </b>
                    </div>
                    <div style="border-bottom:1px solid black;padding:5px 15px;height:70px;">
                   <b style="font-size:19px;">${
                     column.by ? column.by : "\u00A0"
                   }
                   </b>
                    </div>
                    <div style="padding:5px 15px;">
                    <b style="font-size:20px;">
                    <b style="text-transform: 'uppercase'">${column.name}</b> 
                    </b>
                    </div>
                    </div>    
                  </td>`
              )
              .join("")}
          </tr></table>`
        )
        .join("")}
    </div>`;

    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = footerString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return [img, footers.length];
  };
  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:150px;"/>
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const logPageDataIDs = () => {
    const pages = printComponentRef.current.querySelectorAll(".page");
    const dataIDs = [];

    pages.forEach((page, index) => {
      const dataID = page.getAttribute("data-id");
      const [
        ulrNumber,certificateNumber,calibrationDate,settingList,revisionNumber,calibratedby,approvedby,approvedDate,reviewedBy,reviewedDate,clientId,signedBy,signedDate,witnessedBy,witnessDate
      ] = dataID.split("&&");

      const parsedSettingList = JSON.parse(settingList);

      // console.log(`Data ID for Page ${index + 1}:`, {
      //   ulrNumber,certificateNumber,calibrationDate,settingList: parsedSettingList,revisionNumber,calibratedby :calibratedby.split(','),approvedby:approvedby.split(','),approvedDate,reviewedBy:reviewedBy.split(','),reviewedDate,clientId,signedBy:signedBy.split(','),signedDate,witnessedBy:witnessedBy.split(','),witnessDate
      // });

      dataIDs.push({
        page: index + 1,
        ulrNumber,certificateNumber,calibrationDate,settingList: parsedSettingList,revisionNumber,calibratedby :calibratedby.split(','),approvedby:approvedby.split(','),approvedDate,reviewedBy:reviewedBy.split(','),reviewedDate,clientId,signedBy:signedBy.split(','),signedDate,witnessedBy:witnessedBy.split(','),witnessDate
      });
    });

    return dataIDs;
  };

  const generatePDF = async () => {
    let singleCertificateData = logPageDataIDs();
    // console.log("singleCertificateData", singleCertificateData);
    const element = printComponentRef.current;
    const [_, marginNumber] = await generateDynamicFooter();
    let footerHeight = 90;
    let footerPos = [0, 675];
    let pdf = await html2pdf()
      .from(element)
      .set({
        margin: [125, 1, 75 + marginNumber * footerHeight, 1],
        filename: `AllCertificate.pdf`,
        // pagebreak: {
        //   mode: ['avoid-all', 'css', 'legacy'],
        // },
        pagebreak: { after: "section", mode: ["css", "legacy"] },
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          orientation: "portrait",
          unit: "pt",
          format: "a4",
        },
      })
      .toPdf()
      .get("pdf");

    const totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(9);
      pdf.setTextColor(0, 0, 0);
      pdf.setFont("Courier");

      let singleCertificateDataFilter = singleCertificateData.filter(
        (el) => el.page == i
      );

      // console.log("singleCertificateDataFilter",singleCertificateDataFilter)

      let ulrNumber = singleCertificateDataFilter[0]?.ulrNumber;
      let certificateNumber = singleCertificateDataFilter[0]?.certificateNumber;
      // let calibratedby = "BFI";
      // let approvedby = "BFI123";
      // let calibrationDate = singleCertificateDataFilter[0]?.calibrationDate;

      let image = await generateDynamicHeader(
        ulrNumber,
        `${certificateNumber}${
          singleCertificateDataFilter[0]?.settingList["amendment"] === "true" &&
          singleCertificateDataFilter[0]?.revisionNumber > 0
            ? `/AMD-${singleCertificateDataFilter[0]?.revisionNumber}`
            : ""
        }`,
        i,
        totalPages
      );
      pdf.addImage(image, 0, 78, 595, 55);

      let height;
      [image, height] = await generateDynamicFooter(
        singleCertificateDataFilter[0]?.calibratedby,
        singleCertificateDataFilter[0]?.calibrationDate,
        singleCertificateDataFilter[0]?.approvedby,
        singleCertificateDataFilter[0]?.approvedDate,
        singleCertificateDataFilter[0]?.reviewedBy,
        singleCertificateDataFilter[0]?.reviewedDate,
        singleCertificateDataFilter[0]?.clientId,
        singleCertificateDataFilter[0]?.signedBy,
        singleCertificateDataFilter[0]?.signedDate,
        singleCertificateDataFilter[0]?.witnessedBy,
        singleCertificateDataFilter[0]?.witnessDate
      );
      pdf.addImage(
        image,
        footerPos[0],
        height > 1 ? footerPos[1] - (height - 1) * footerHeight : footerPos[1],
        595,
        height * footerHeight
      );
      // pdf.addImage(image, 0, 675, 595, 100);

      const text = "CALIBRATION CERTIFICATE";
      const pageWidth = pdf.internal.pageSize.getWidth();
      const textWidth =
        (pdf.getStringUnitWidth(text) * 12) / pdf.internal.scaleFactor;
      const x = (pageWidth - textWidth) / 2;
      pdf.setFontSize(12);
      pdf.text(text, x, 85);
      pdf.setFont("helvetica", "normal");

      if (letterHead) {
        let image = await generateLetterHeadHeader();
        pdf.addImage(image, 0, 0, 595, 70);

        image = await generateLetterHeadFooter();
        pdf.addImage(image, 0, 790, 595, 50);
      }

      if (electronicSign) {
        pdf.setFontSize(9);
        const text =
          "This certificate is electronically signed and does not required physical signature";
        const textWidth =
          (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize()) /
          pdf.internal.scaleFactor;
        const pageWidth = pdf.internal.pageSize.getWidth();
        const x = (pageWidth - textWidth) / 2;
        pdf.text(text, x, 765);
      }

      if (i === totalPages) {
        pdf.setFontSize(9);
        const text = "--------- END OF CALIBRATION CERTIFICATE ---------";
        // const text1 =
        //   certificateDownloadCount?.certificateDownloadCounter > 0
        //     ? `Download No.${certificateDownloadCount.certificateDownloadCounter}`
        //     : ".";

        const textWidth =
          (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize()) /
          pdf.internal.scaleFactor;
        const pageWidth = pdf.internal.pageSize.getWidth();
        const x = (pageWidth - textWidth) / 2;
        pdf.text(text, x, 778);
        // pdf.text(text1, pageWidth - 83, 778);
      }
    }

    pdf.save();
    // countOfCertificatesAnalytics(certificateIdsArray);
  };

  return (
    <div>
      <Grid container spacing={2} align="left" style={{ display: "flex" }}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="certificate-certificateviewletterhead"
            options={[
              { id: 1, label: "With Letter Head" },
              { id: 2, label: "Without Letter Head" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="letterHead" />
            )}
            onInputChange={(event, newInputValue) => {
              if (
                newInputValue === "With Letter Head" ||
                newInputValue === ""
              ) {
                setLetterHead(true);
              } else if (newInputValue === "Without Letter Head") {
                setLetterHead(false);
              }
              // newInputValue == "Approve" ? setEnableDisable() : setEnableDisable(4+':'+result.toString());
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="certificate-certificateviewelectronicsign"
            options={[
              { id: 1, label: "With Electronically Signed" },
              { id: 2, label: "Without  Electronically Signed" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="Electronically Signed" />
            )}
            onInputChange={(event, newInputValue) => {
              if (
                newInputValue === "With Electronically Signed" ||
                newInputValue === ""
              ) {
                setElectronicSign(true);
              } else if (newInputValue === "Without  Electronically Signed") {
                setElectronicSign(false);
              }
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            id="certificate-certificateviewDownalod"
            variant="contained"
            size="small"
            sx={{ ml: 3 }}
            onClick={() => {
              generatePDF();
            }}
          >
            Download PDF
          </Button>
        </Grid>
      </Grid>
      <div ref={printComponentRef}>
        {certificateIdsArray.map((e, index) => (
          <div style={{ pageBreakAfter: "always" }}>
          <div style={{ marginTop: "10px", }}>
            <ViewCertificate
              key={index}
              certificateIds={certificateIdsArray[index]}
            />
          </div>
          </div>
        ))}
      </div>
    </div>
  );
}
