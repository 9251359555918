import React, { useContext, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { Checkbox } from "@mui/material";
import { BASE_URL } from "../../../global";
import { toast } from "react-toastify";
import axios from "axios";
import { DatasheetContext } from "./datasheet.context.js";
import { ClassicTable } from "../../../utils/components/Styles.js";

const checkboxOptions = ["✔", "NA"];

export const onSubmitOpinionAndAnalysis = async (
  analysisSelectedOption,
  datasheetId
) => {

  try {
    let payload = {
      ...analysisSelectedOption,
      datasheetId: Number(datasheetId),
      certificateId: Number(datasheetId),
    };

    if (analysisSelectedOption?.id) {
      await axios
        .patch(
          BASE_URL + `opinionAndAnalysis/${analysisSelectedOption?.id}`,
          payload
        )
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          toast.error("error : ", err);
        });
    } else {
      await axios
        .post(BASE_URL + `opinionAndAnalysis`, payload)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          toast.error("error : ", err);
          
        });
    }
    return true;
  } catch (error) {
    // Handle errors
    console.error("Error in onSubmit:", error);
    return false;
  }
};

const OpinionAndAnalysisTable = ({ datasheetId }) => {
  const datasheetContextValue = useContext(DatasheetContext);
  const {
    state: { analysisSelectedOption },
    dispatch,
  } = datasheetContextValue;

  const handleChangeForCheckbox = (event, keyName) => {
    const newValue = event.target.checked
      ? event.target.value === "✔"
        ? true
        : false
      : "";

    analysisSelectedOption[keyName] = newValue;
    dispatch({
      field: "analysisSelectedOption",
      value: analysisSelectedOption,
    });
  };

  const convertToBooleanOrEmpty = (value) => {
    if (value === "1") return true;
    if (value === "") return "";
    return false;
  };

  const getOpinionAndAnalysisList = () => {
    let url = BASE_URL;
    const payload = {
      query: `SELECT * FROM opinionAndAnalysis WHERE datasheetId = ${datasheetId}`,
    };
    axios
      .post(url + "dynamic", payload)
      .then((res) => {
        if (res.data.length > 0) {
          const parsedData = {
            ...res.data[0],
            accepted: convertToBooleanOrEmpty(res.data[0].accepted),
            limitedUse: convertToBooleanOrEmpty(res.data[0].limitedUse),
            calibrated: convertToBooleanOrEmpty(res.data[0].calibrated),
            rejected: convertToBooleanOrEmpty(res.data[0].rejected),
          };

          dispatch({
            field: "analysisSelectedOption",
            value: parsedData,
          });
          // setAnalysisSelectedOption(parsedData);
        }
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  useEffect(() => {
    getOpinionAndAnalysisList();
  }, []);

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Opinion And Analysis Table
      </Typography>
      <div style={{ width: "100%", overflow: "auto" }}>
      <ClassicTable>
        <Table sx={{ minWidth: 660 }}  aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell>
                <div style={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{marginRight: "25px", typography: { sm: 'body1', xs: 'body2' } }} >
                    Accepted - Validate for use
                </Typography>

                  {checkboxOptions.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={
                            analysisSelectedOption?.accepted === (item === "✔")
                          }
                          onChange={(event) =>
                            handleChangeForCheckbox(event, "accepted")
                          }
                          value={item}
                        />
                      }
                      label={<Typography variant="body1">{item}</Typography>}
                    />
                  ))}
                </div>
              </TableCell>

              <TableCell>
                <div style={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{marginRight: "25px", typography: { sm: 'body1', xs: 'body2' } }} >
                Calibrated
                </Typography>

                  {checkboxOptions.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={
                            analysisSelectedOption?.calibrated ===
                            (item === "✔")
                          }
                          onChange={(event) =>
                            handleChangeForCheckbox(event, "calibrated")
                          }
                          value={item}
                        />
                      }
                      label={<Typography variant="body1">{item}</Typography>}
                    />
                  ))}
                </div>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <div style={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{marginRight: "25px", typography: { sm: 'body1', xs: 'body2' } }} >
                    Limited Use (Refer - Result)
                </Typography>

                  {checkboxOptions.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={
                            analysisSelectedOption?.limitedUse ===
                            (item === "✔")
                          }
                          onChange={(event) =>
                            handleChangeForCheckbox(event, "limitedUse")
                          }
                          value={item}
                        />
                      }
                      label={<Typography variant="body1">{item}</Typography>}
                    />
                  ))}
                </div>
              </TableCell>

              <TableCell>
                <div style={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ marginRight: "25px",typography: { sm: 'body1', xs: 'body2' } }} >
                Rejected/Out of Use
                </Typography>

                  {checkboxOptions.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={
                            analysisSelectedOption?.rejected === (item === "✔")
                          }
                          onChange={(event) =>
                            handleChangeForCheckbox(event, "rejected")
                          }
                          value={item}
                        />
                      }
                      label={<Typography variant="body1">{item}</Typography>}
                    />
                  ))}
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ClassicTable>
      </div>
    </div>
  );
};

export default OpinionAndAnalysisTable;
