import { BASE_URL } from "../../../global";

const axios = require("axios");

let global_datasheetReadings = null;

async function executeQuery(query) {
  let data = {
    query: query,
  };

  let res = await axios.post(BASE_URL + `dynamic`, data);
  return res;
}

async function deleteTable(tableName) {
  // Generate the DROP TABLE query dynamically
  const query = `DROP TABLE IF EXISTS ${tableName}`;

  // Execute the DROP TABLE query
  let res = await executeQuery(query);
}

async function createTable(tableName, columns) {
  // Generate the CREATE TABLE query dynamically
  const query = `CREATE TABLE IF NOT EXISTS ${tableName} (${columns
    .map((col, index) => `col${index + 1} CHAR(255)`)
    .join(", ")})`;

  // Execute the CREATE TABLE query
  let res = await executeQuery(query);
}

async function insertData(tableName, data) {
  // Generate the INSERT INTO query dynamically
  const columns = Object.keys(data[0]).map((col, index) => `col${index + 1}`);
  const values = data.map((row) =>
    Object.values(row)
      .map((value) => `'${value}'`)
      .join(", ")
  );
  const query = `INSERT INTO ${tableName} (${columns.join(
    ", "
  )}) VALUES (${values.join("), (")})`;

  // Execute the INSERT INTO query
  let res = await executeQuery(query);
}

function separateDataByTableId(datasheetReadings) {
  const separatedData = {};

  datasheetReadings.forEach((reading) => {
    const tableId = reading[0];
    const thirdValue = reading[2];

    if (thirdValue.includes("_unit_") || thirdValue.includes("_rh_")) {
      // Skip the list if the third value contains '_unit_' or 'rh_'
      return;
    }

    if (separatedData[tableId]) {
      separatedData[tableId].push(reading.slice(1));
    } else {
      separatedData[tableId] = [reading.slice(1)];
    }
  });

  return separatedData;
}

async function fetchSteps(instrumentId) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        BASE_URL + `datasheetTemplate?_where=(instrumentId,eq,${instrumentId})`
      )
      .then((res) => {
        if (res?.data[0]?.config) resolve(JSON.parse(res?.data[0]?.config));
        else resolve(null);
      })
      .catch((err) => {
        console.error("Something Went Wrong while fetching datasheetTemplate!");
        reject(err);
      });
  });
}

async function setValToTable(source, value) {
  source = source.split(".");
  let tableId, columnId, rowId;
  if (source[0].includes("tid")) {
    tableId = source[0].split("tid")[1];
  }
  if (source[1].includes("col")) {
    columnId = source[1].split("col")[1];
  }
  if (source[2].includes("row")) {
    rowId = source[2].split("row")[1];
  } else if (source[2].includes("all")) {
    rowId = "all";
  }

  let rowCount = 0;
  for (const element of global_datasheetReadings) {
    if (element[0] == tableId) {
      rowCount += 1;

      if (rowId == "all" || rowCount == rowId) {
         // skip unit and row header rows
         if (element[2].includes("_unit_")) continue;
         if (element[2].includes("_rh_")) continue;
        
         // prepend $ and remove excessive $ if any 
         element[columnId - 1 + 2] = "$" + value;
        //  replace all $ with only one $
        element[columnId - 1 + 2] = element[columnId - 1 + 2].replace(/\$+/g, "$");
      }
    }
  }
}

async function updateDummyTables(action = "update", tableName = null) {
  let t1 = performance.now();
  // seperate out data according to table ids
  let tables = separateDataByTableId(global_datasheetReadings);

  let table_keys = Object.keys(tables);
  for (let i = 0; i < table_keys?.length; i++) {
    let tableName = `t${i + 1}`;
    // delete table if exist
    await deleteTable(tableName);
    // create table in tables_id's asc order
    let dummy_cols = Array.from(
      { length: tables[table_keys[i]]?.[0].length },
      (_, i) => ""
    );

    await createTable(tableName, dummy_cols);
    // insert data into table
    await insertData(tableName, tables[table_keys[i]]);
    let t2 = performance.now();
  }
}

export default async function processDatasheetTemplate(
  datasheetReadings,
  instrumentId
) {
  global_datasheetReadings = datasheetReadings;
  await updateDummyTables("create");

  let steps = await fetchSteps(instrumentId);
  for (let i = 0; i < steps?.length; i++) {
    let instructions = steps[i]?.instructions;
    let outputStream = steps[i]?.outputStream;
    for (let i = 0; i < instructions?.length; i++) {
      let res = await executeQuery(instructions[i]);
      if (outputStream.includes("tid"))
        await setValToTable(outputStream, res?.data?.[0].col1);
    }

    await updateDummyTables();
  }

  return global_datasheetReadings;
}
