import { driver } from "driver.js";

export const editstdMastersEQPGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in editing Master EQP.",
        },
      },
      {
        element: "#edit_stdMastersEQP_discipline",
        popover: {
          title: "Edit Discipline",
          description: "Select discipline using dropdown.",
        },
      },
      {
        element: "#edit_stdMastersEQP_master_eqp",
        popover: {
          title: "Master EQP Name",
          description: "Edit Master EQP name.",
        },
      },
      {
        element: "#edit_stdMastersEQP_master_id",
        popover: {
          title: "Master ID",
          description: "Edit Master ID.",
        },
      },
      {
        element: "#edit_stdMastersEQP_master_srno",
        popover: {
          title: "Serial Number",
          description: "Edit serial number.",
        },
      },
      {
        element: "#edit_stdMastersEQP_master_make",
        popover: {
          title: "Make",
          description: "Edit make.",
        },
      },
      {
        element: "#edit_stdMastersEQP_master_model",
        popover: {
          title: "Model",
          description: "Edit model.",
        },
      },
      {
        element: "#edit_stdMastersEQP_master_traceability",
        popover: {
          title: "Traceability",
          description: "Edit traceability.",
        },
      },
      {
        element: "#edit_stdMastersEQP_master_calagency",
        popover: {
          title: "Calibration Agency",
          description: "Edit calibration agency.",
        },
      },
      {
        element: "#edit_stdMastersEQP_master_caldate",
        popover: {
          title: "Calibration Date",
          description: "Edit calibration date.",
        },
      },
      {
        element: "#edit_stdMastersEQP_master_duedate",
        popover: {
          title: "Due Date",
          description: "Edit Due date.",
        },
      },
      {
        element: "#edit_stdMastersEQP_master_location",
        popover: {
          title: "Location",
          description: "Edit location.",
        },
      },
      {
        element: "#edit_stdMastersEQP_master_range",
        popover: {
          title: "Range",
          description: "Edit range.",
        },
      },
      {
        element: "#edit_stdMastersEQP_master_accuracy",
        popover: {
          title: "Accuracy",
          description: "Edit accuracy.",
        },
      },
      {
        element: "#edit_stdMastersEQP_master_lc",
        popover: {
          title: "Least Count",
          description: "Edit least count.",
        },
      },
      {
        element: "#edit_stdMastersEQP_master_materialtype",
        popover: {
          title: "Material Type",
          description: "Edit Material Type.",
        },
      },
      {
        element: "#edit_stdMastersEQP_master_addmasterrange",
        popover: {
          title: "Add Master Range",
          description: "Click this button to add a new row below.",
        },
      },
      {
        element: "#edit_stdMastersEQP_master_copymasterrange",
        popover: {
          title: "Copy Master Range",
          description:
            "Click this button to copy the current row and create a new row with the same values.",
        },
      },
      {
        element: "#edit_stdMastersEQP_master_callowerpt",
        popover: {
          title: "Edit Value",
          description: "Edit the value.",
        },
      },
      {
        element: "#edit_stdMastersEQP_master_editunit",
        popover: {
          title: "Edit Unit",
          description: "Select unit.",
        },
      },
      {
        element: "#edit_stdMastersEQP_dispatchchalland_delete",
        popover: {
          title: "Delete Row",
          description: "Click this button to delete the current row.",
        },
      },
      {
        element: "#edit_stdMastersEQP_dispatchchalland_update",
        popover: {
          title: "Update Master EQP",
          description: "Click this button to save your updated Master EQP.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can edit Master EQP.",
        },
      },
    ],
  });
};

export const createstdMastersEQPGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in creating Master EQP.",
        },
      },
      {
        element: "#create_stdMastersEQP_discipline",
        popover: {
          title: "Create Discipline",
          description: "Select discipline using dropdown.",
        },
      },
      {
        element: "#create_stdMastersEQP_master_eqp",
        popover: {
          title: "Master EQP Name",
          description: "Enter Master EQP name.",
        },
      },
      {
        element: "#create_stdMastersEQP_master_id",
        popover: {
          title: "Master ID",
          description: "Enter Master ID.",
        },
      },
      {
        element: "#create_stdMastersEQP_master_srno",
        popover: {
          title: "Serial Number",
          description: "Enter serial number.",
        },
      },
      {
        element: "#create_stdMastersEQP_master_make",
        popover: {
          title: "Make",
          description: "Enter make.",
        },
      },
      {
        element: "#create_stdMastersEQP_master_model",
        popover: {
          title: "Model",
          description: "Enter model.",
        },
      },
      {
        element: "#create_stdMastersEQP_master_traceability",
        popover: {
          title: "Traceability",
          description: "Enter traceability.",
        },
      },
      {
        element: "#create_stdMastersEQP_master_calagency",
        popover: {
          title: "Calibration Agency",
          description: "Enter calibration agency.",
        },
      },
      {
        element: "#create_stdMastersEQP_master_caldate",
        popover: {
          title: "Calibration Date",
          description: "Select calibration date.",
        },
      },
      {
        element: "#create_stdMastersEQP_master_duedate",
        popover: {
          title: "Due Date",
          description: "Enter due date.",
        },
      },
      {
        element: "#create_stdMastersEQP_master_location",
        popover: {
          title: "Location",
          description: "Enter location.",
        },
      },
      {
        element: "#create_stdMastersEQP_master_range",
        popover: {
          title: "Range",
          description: "Enter range.",
        },
      },
      {
        element: "#create_stdMastersEQP_master_accuracy",
        popover: {
          title: "Accuracy",
          description: "Enter accuracy.",
        },
      },
      {
        element: "#create_stdMastersEQP_master_lc",
        popover: {
          title: "Least Count",
          description: "Enter least count.",
        },
      },
      {
        element: "#create_stdMastersEQP_master_materialtype",
        popover: {
          title: "Material Type",
          description: "Select material type.",
        },
      },
      {
        element: "#create_stdMastersEQP_master_addmasterrange",
        popover: {
          title: "Add Master Range",
          description: "Click this button to add a new row below.",
        },
      },
      {
        element: "#create_stdMastersEQP_master_copymasterrange",
        popover: {
          title: "Copy Master Range",
          description:
            "Click this button to copy the current row and create a new row with the same values.",
        },
      },
      {
        element: "#create_stdMastersEQP_master_callowerpt",
        popover: {
          title: "Enter Value",
          description: "Enter value.",
        },
      },
      {
        element: "#create_stdMastersEQP_master_createunit",
        popover: {
          title: "Select Unit",
          description: "Select unit.",
        },
      },
      {
        element: "#create_stdMastersEQP_dispatchchalland_delete",
        popover: {
          title: "Delete Row",
          description: "Click this button to delete the current row.",
        },
      },
      {
        element: "#create_stdMastersEQP_dispatchchalland_update",
        popover: {
          title: "Save Master EQP",
          description: "Click this button to save Master EQP.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can create Master EQP.",
        },
      },
    ],
  });
};


export const MastersEQPListGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Master EQP List.",
        },
      },
      {
        element: "#stdMastersEQPList_createbtn",
        popover: {
          title: "Create New Master EQP",
          description: "When you click this button, you can create a new EQP.",
        },
      },
      {
        element: "#stdMastersEQPList_searchby",
        popover: {
          title: "Search Field",
          description: "Use this dropdown to search.",
        },
      },
      {
        element: "#stdMastersEQPList_searchbytext",
        popover: {
          title: "Enter Your Value",
          description:
            "When you select a previous dropdown value, enter the value you want to search.",
        },
      },
      {
        element: "#stdMastersEQPList_searchbtn",
        popover: {
          title: "Search",
          description:
            "After selecting a previous dropdown or entering a value, click this button to get results.",
        },
      },
      {
        element: "#stdMastersEQPList_convertexcel",
        popover: {
          title: "Convert Excel",
          description:
            "When you click this button below the list, it gets converted into an Excel file.",
        },
      },
      {
        element: "#stdMastersEQPList_table",
        popover: {
          title: "Master EQP Table",
          description:
            "Table displaying details where you can edit Master EQP and Delete.",
        },
      },
      {
        element: "#stdMastersEQPList_edit",
        popover: {
          title: "Edit Master EQP",
          description: "Click this button to edit the Master EQP.",
        },
      },
      {
        element: "#stdMastersEQPList_copy",
        popover: {
          title: "Copy Master EQP",
          description:
            "Click this button to copy this Master EQP and create a new Master EQP with existing values.",
        },
      },
      {
        element: "#stdMastersEQPList_delete",
        popover: {
          title: "Master EQP Delete",
          description: "When clicking this button, you can delete Master EQP.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can create Master EQP.",
        },
      },
    ],
  });
};
