import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddIcon from "@mui/icons-material/Add";
import { ClassicTable } from "../../utils/components/Styles";

const axios = require("axios");

export default function EditStandardInOut() {
  const [companyName, setCompanyName] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [standards, setStandards] = React.useState("");
  const [purpose, setPurpose] = React.useState("");
  const [personName, setPersonName] = React.useState("");
  const [remark, setRemark] = React.useState("");
  const [actualReturnDate, setActualReturnDate] = React.useState(null);
  const [oActualReturnDate, setOActualReturnDate] = React.useState(null);
  const [standardArray, setStandardArray] = React.useState([]);
  const [srfObject, createSrfObject] = React.useState({});
  const [checkPoint, setCheckPoint] = React.useState("");
  const [standardsArray, setStandardsArray] = React.useState([]);
  const [readingRows, setReadingRows] = React.useState([]);
  const [expectedReturnDate, setExpectedReturnDate] = React.useState(null);
  const [siteInwardDate, setSiteInwardDate] = React.useState(null);

  const readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
      },
      {
        field: "standards",
        headerName: "standards",
        editable: true,
      },
      {
        field: "checkPoint",
        headerName: "check Point",
        editable: true,
      },
      {
        field: "returnDate",
        headerName: "Return Date",
        editable: true,
      },
    ],
  ];

  const params = useParams();

  const initiateChData = () => {
    try {
      let rows = [];
      for (let i = 0; i < standardsArray.length; i++) {
        rows.push([
          standardsArray[i].id,
          `${standardsArray[i].stId}:${standardsArray[i].standardName}`,
          checkPoint.split(",")[0],
          expectedReturnDate,
        ]);
      }
      for (let i = 0; i < standardsArray.length; i++) {
        rows.push([
          standardsArray[i].id,
          `${standardsArray[i].stId}:${standardsArray[i].standardName}`,
          checkPoint.split(",")[1] || "",
          actualReturnDate,
        ]);
      }
      console.log(rows);
      setReadingRows(rows);
    } catch (error) {
      console.log(error);
    }
  };
  const customerList = [];
  for (let i = 0; i < standardArray.length; i++) {
    customerList.push({
      label: standardArray[i].id + ", " + standardArray[i].name,
    });
  }

  var refresh = () => {
    window.location.reload(false);
  };

  const getStandardInOutList = () => {
    let url = BASE_URL;
    axios
      .get(
        url +
          `xjoin?_join=stInOut.standardInOut,_j,client.clients&_on1=(stInOut.clientId,eq,client.id)&_fields=client.companyName,client.address,stInOut.clientId,stInOut.standardIds,stInOut.standards,stInOut.purpose,stInOut.outwardDate,stInOut.expectedReturnDate,stInOut.siteInwardDate,stInOut.personName,stInOut.actualReturnDate,stInOut.remark,stInOut.checkPoint&_where=(stInOut.id,eq, ${params.id})`
      )
      .then((res) => {
        console.log(res.data);
        setCompanyName(res.data[0].client_companyName);
        setAddress(res.data[0].client_address);
        setStandards(res.data[0].stInOut_standards);
        setPurpose(res.data[0].stInOut_purpose);
        setPersonName(res.data[0].stInOut_personName);
        setRemark(res.data[0].stInOut_remark);
        setActualReturnDate(res.data[0].stInOut_actualReturnDate);
        setOActualReturnDate(res.data[0].stInOut_actualReturnDate);
        setCheckPoint(res.data[0].stInOut_checkPoint);
        setExpectedReturnDate(res.data[0].stInOut_expectedReturnDate);
        setSiteInwardDate(res.data[0].stInOut_siteInwardDate);
        if (res.data[0].stInOut_standards != null) {
          axios
            .get(url + `standards/bulk?_ids=${res.data[0].stInOut_standards}`)
            .then((res) => {
              setStandardsArray(res.data);
            })
            .catch((error) => {
              toast.error("Something Went Wrong!");
            });
        }
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const handleSubmit = (event) => {
    let row = {
      checkPoint:
        checkPoint.split(",").length == 2
          ? checkPoint
          : `${checkPoint},${readingRows[readingRows.length / 2 - 1][2]}`,
      actualReturnDate: actualReturnDate
        ? moment(actualReturnDate).format("YYYY-MM-DD")
        : null,
      siteInwardDate: siteInwardDate
        ? moment(siteInwardDate).format("YYYY-MM-DD")
        : null,
    };

    console.log(readingRows);
    console.log(row);
    axios
      .patch(BASE_URL + `standardInOut/${params.id}`, row)
      .then((res) => {
        toast.success("Successfully Updated!");
        setTimeout(() => refresh(), 500);
      })
      .catch((err) => {
        console.log("std in-out update error : ", err);
        toast.error("update error : ", err);
      });
  };

  //ToDo: fetch cutomers from database

  // data operations
  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    };
    newSrfObject[key] = value;
    createSrfObject(newSrfObject);
  };
  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    getStandardInOutList();
  }, []);

  useEffect(() => {
    initiateChData();
  }, [standardsArray]);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Master In Entry
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Company Name"
            size="small"
            disabled
            value={companyName}
            fullWidth
            variant="outlined"
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Address"
            size="small"
            disabled
            value={address}
            fullWidth
            variant="outlined"
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Purpose"
            size="small"
            value={purpose}
            fullWidth
            disabled
            variant="outlined"
            onChange={(e) => setPurpose(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Person Name"
            size="small"
            value={personName}
            fullWidth
            disabled
            variant="outlined"
            onChange={(e) => setPersonName(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Site Inward Date"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={siteInwardDate ? new Date(siteInwardDate) : ""}
              onChange={(e) => setSiteInwardDate(e)}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Remarks"
            size="small"
            value={remark}
            fullWidth
            disabled
            variant="outlined"
            onChange={(e) => setRemark(e.target.value)}
          />
        </Grid>
      </Grid>
      <br />
      <hr />
      <h4 style={{ "margin-bottom": "0px" }}>Master Records</h4>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    if (cellIndex === 2 && index % standardsArray.length === 0)
                      return (
                        <TableCell rowSpan={standardsArray.length}>
                          <TextField
                            size="small"
                            value={cell}
                            disabled={
                              index < standardsArray.length || oActualReturnDate
                            }
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                        </TableCell>
                      );
                    if (cellIndex === 1) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            style={{ width: "100%" }}
                            value={cell}
                            disabled
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                        </TableCell>
                      );
                    }
                    if (cellIndex === 3 && index % standardsArray.length === 0)
                      return (
                        <TableCell
                          rowSpan={standardsArray.length}
                          style={{ width: "200px" }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label={
                                standardsArray.length <= index
                                  ? "Actual Return Date *"
                                  : "Expeced Return Date"
                              }
                              inputFormat="dd/MM/yyyy"
                              format="dd/MM/yyyy"
                              InputLabelProps={{ shrink: true }}
                              value={
                                (
                                  index < standardsArray.length
                                    ? expectedReturnDate
                                    : actualReturnDate
                                )
                                  ? new Date(
                                      index < standardsArray.length
                                        ? expectedReturnDate
                                        : actualReturnDate
                                    )
                                  : ""
                              }
                              onChange={(newValue) => {
                                setActualReturnDate(newValue);
                              }}
                              disabled={
                                standardsArray.length > index ||
                                oActualReturnDate
                              }
                              renderInput={(params) => (
                                <TextField {...params} size="small" fullWidth />
                              )}
                            />
                          </LocalizationProvider>
                        </TableCell>
                      );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>

      <br />
      <Toolbar style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "3rem", }}>
      <Button
          variant="contained"
          style={{backgroundColor:"grey"}}
          size="small"
           onClick={() => {
    if (window.confirm("Are you sure you want to cancel?")) {
      window.history.back();
    }
  }}
        >
          Cancel
        </Button>
        {!oActualReturnDate && (
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              handleSubmit();
            }}
          >
            Update Standard In/Out
          </Button>
        )}
      </Toolbar>
    </Paper>
  );
}
