import { driver } from "driver.js";

export const ducMasterListGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this DucMaster List.",
        },
      },
      {
        element: "#ducMaster_list_createbtn",
        popover: {
          title: "Create New DucMaster",
          description: "Click this button to create a new DucMaster.",
        },
      },
      {
        element: "#ducMaster_list_searchby",
        popover: {
          title: "Search Field",
          description: "Use this dropdown to search.",
        },
      },
      {
        element: "#ducMaster_list_searchbytext",
        popover: {
          title: "Enter Your Value",
          description:
            "When selecting a previous dropdown value, enter the value you want to search.",
        },
      },
      {
        element: "#ducMaster_list_searchbtn",
        popover: {
          title: "Search",
          description:
            "After selecting a previous dropdown or entering a value, click this button to get results.",
        },
      },
      {
        element: "#ducMaster_list_uploadexcel",
        popover: {
          title: "Upload Excel",
          description: "Click this button to upload an Excel file.",
        },
      },
      {
        element: "#ducMaster_list_table",
        popover: {
          title: "DucMaster Table",
          description:
            "Table displaying details where you can edit and delete entries.",
        },
      },
      {
        element: "#ducMaster_list_edit",
        popover: {
          title: "Edit",
          description: "Click this button to edit.",
        },
      },
      {
        element: "#ducMaster_list_copy",
        popover: {
          title: "Copy",
          description:
            "Click this button to copy; it will create a new DucMaster with existing values.",
        },
      },
      {
        element: "#ducMaster_list_delete",
        popover: {
          title: "Delete",
          description: "Click this button to delete.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can create DucMasters.",
        },
      },
    ],
  });
};

export const createDucMasterGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in creating or editing a DucMaster.",
        },
      },
      {
        element: "#ducMaster_create_companyname",
        popover: {
          title: "Company Name",
          description: "Select the company name from the dropdown.",
        },
      },
      {
        element: "#ducMaster_create_instrumentname",
        popover: {
          title: "Instrument Name",
          description: "Select the instrument name from the dropdown.",
        },
      },
      {
        element: "#ducMaster_create_ducid",
        popover: {
          title: "DUCID",
          description: "Enter the unique identifier for the DucMaster.",
        },
      },
      {
        element: "#ducMaster_create_serialno",
        popover: {
          title: "Serial Number",
          description: "Enter the serial number for the DucMaster.",
        },
      },
      {
        element: "#ducMaster_create_make",
        popover: {
          title: "Make",
          description: "Enter the make of the DucMaster.",
        },
      },
      {
        element: "#ducMaster_create_model",
        popover: {
          title: "Model",
          description: "Enter the model of the DucMaster.",
        },
      },
      {
        element: "#ducMaster_create_locinst",
        popover: {
          title: "Location of Instruments",
          description:
            "Enter the location of the instruments for the DucMaster.",
        },
      },
      {
        element: "#ducMaster_create_dept",
        popover: {
          title: "Department",
          description:
            "Enter the department name associated with the DucMaster.",
        },
      },
      {
        element: "#ducMaster_create_calfreq",
        popover: {
          title: "Calibration Frequency",
          description: "Select the calibration frequency from the dropdown.",
        },
      },
      {
        element: "#ducMaster_create_ranges",
        popover: {
          title: "Range",
          description:
            "Click this button to open a modal and enter the range for the DucMaster.",
        },
      },
      {
        element: "#ducMaster_create_lc",
        popover: {
          title: "Least Count",
          description:
            "Click this button to open a modal and enter the least count for the DucMaster.",
        },
      },
      {
        element: "#ducMaster_create_operatingRange",
        popover: {
          title: "Operating Range",
          description:
            "Click this button to open a modal and enter the operating range for the DucMaster.",
        },
      },
      {
        element: "#ducMaster_create_accuracy",
        popover: {
          title: "Accuracy",
          description:
            "Click this button to open a modal and enter the accuracy for the DucMaster.",
        },
      },
      {
        element: "#ducMaster_create_save",
        popover: {
          title: "Save DucMaster",
          description: "Click this button to save the DucMaster.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can edit/create the DucMaster.",
        },
      },
    ],
  });
};
