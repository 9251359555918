import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import { Link } from "react-router-dom";

import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import { Table } from "@mui/material";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No", minWidth: 40 },

  {
    id: "date",
    label: "Date",
    align: "left",
    minWidth: 40,
  },
  {
    id: "amount",
    label: "Amount",
    align: "left",
    minWidth: 40,
  },
  {
    id: "paymentMode",
    label: "Payment Mode",
    align: "left",
    minWidth: 40,
  },
  {
    id: "nameOfPerson",
    label: "Name Of Person",
    align: "left",
    minWidth: 40,
  },
  {
    id: "reason",
    label: "Reason",
    align: "left",
    minWidth: 40,
  },
];

function createData(id, date, amount, paymentMode, nameOfPerson, reason) {
  return {
    id,
    date,
    amount,
    paymentMode,
    nameOfPerson,
    reason,
  };
}

export default function ExpenseLsit() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [expenseList, setExpenseList] = React.useState("");
  const [rowDaata, setRowDaata] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    console.log(id);

    var jsonData = {
      status: -1,
    };
    let url = BASE_URL;
    axios
      .patch(url + `expenses/${id}`, jsonData)
      .then((res) => {
        toast("expense was deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  const getExpenseList = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "expenses?_where=(status,eq,1)&_sort=-id")
      .then((res) => {
        setExpenseList(res.data);
        initializeDataTable();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const initializeDataTable = () => {
    $("#datatable-keytable").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      bPaginate: false,
      bLengthChange: true,
      bFilter: true,
      bInfo: false,
      bAutoWidth: true,
      language: {
        zeroRecords: " ",
      },

      buttons: ["copy", "csv", "excel", "print"],
    });
  };

  const initializeExpenseData = () => {
    var rowData = [];
    for (let i = 0; i < expenseList.length; i++) {
      rowData.push(
        createData(
          expenseList[i].id,
          moment(
            expenseList[i].expenseDate ? expenseList[i].expenseDate : ""
          ).format("DD-MM-YYYY"),
          expenseList[i].amount,
          expenseList[i].paymentMode,
          expenseList[i].nameOfPerson,
          expenseList[i].reason
        )
      );
    }
    setRowDaata(rowData);
  };

  useEffect(() => {
    getExpenseList();
  }, []);

  useEffect(() => {
    initializeExpenseData();
  }, [expenseList]);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Toolbar>
        <Button
          variant="contained"
          size="small"
          component={Link}
          to="/master/expense/createExpense"
          onClick={() => {
            {
            }
          }}
        >
          <b>create EXPENSE</b>
        </Button>
      </Toolbar>
      <hr />

      <Table stickyHeader aria-label="sticky table" size="small">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowDaata
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    let value = row[column.id];

                    if (column.id == "id") {
                      value = page * rowsPerPage + index + 1;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}

                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <Tooltip title="Payment Voucher" placement="top-start">
                        <Button
                          component={Link}
                          to={`/master/expense/paymentVoucher/${row.id}`}
                        >
                          <NewspaperIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Edit Expense" placement="top-start">
                        <Button
                          component={Link}
                          to={`/master/expense/editExpense/${row.id}`}
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete Expense" placement="top-start">
                        <Button
                          onClick={(e) => {
                            if (
                              window.confirm("Really want to delete expense?")
                            ) {
                              submitSiteDelete(row.id);
                            }
                          }}
                        >
                          <DeleteIcon style={{ color: "#dc3545" }} />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rowDaata.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ToastContainer />
    </TableContainer>
  );
}
