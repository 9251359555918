//po

import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  TextField,
  Toolbar,
  Tooltip,
} from "@mui/material";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
} from "@mui/material";
import { BASE_URL } from "../../global";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { ToastContainer, toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import { showComponent } from "../helper/helpers";
import { Link } from "react-router-dom";
import DownloadIcon from '@mui/icons-material/Download';
import PreviewIcon from "@mui/icons-material/Preview";
import SearchIcon from "@mui/icons-material/Search";
import { saveAs } from "file-saver";
import EditIcon from '@mui/icons-material/Edit';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";

const Po_acceptanceList = () => {
  const [opTableData, setOpTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const slicedData = opTableData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const search = () => {
    getPoTableData();
  };

  const getPoTableData = () => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey !== "") {
      let colName = {
        "PO Number": "poNumber",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` po.poDate between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }
    let data = {
      query: `select po.quotationDate,po.quotationId,qt.quotationNumber,po.poFileUrl,po.poNumber,po.poDate,po.amount,po.id from poAcceptance as po left join quotation as qt on qt.id = po.quotationId  ${whereConditions} order by po.id DESC`,
    };

    let url = BASE_URL;
    axios
      .post(url + `dynamic`, data)
      .then((res) => {
        setOpTableData(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const submitSiteDelete = (id) => {
    let url = BASE_URL;
    axios
      .delete(url + `poAcceptance/${id}`)
      .then((res) => {
        getPoTableData();
        toast("Po_Acceptance  was deleted!");
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const handleDownload = (row) => {
    if (row.poFileUrl) {
      axios({
        url: row.poFileUrl,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const blob = new Blob([response.data], { type: response.headers["content-type"] });
          saveAs(blob, "PoFile");
        })
        .catch((error) => {
          toast.error("Error downloading file.");
        });
    } else {
      toast.error("No file uploaded to download.");
    }
  };

  useEffect(() => {
    getPoTableData();
  }, []);

  return (
    <div>
      <Box>
        <Toolbar>
          <Button
            variant="contained"
            size="small"
            component={Link}
            to="/poAcceptance/create"
          >
            <b>create po</b>
          </Button>
        </Toolbar>
      </Box>
      <div
        className="mb-2"
        style={{ display: "flex", float: "right", justifyContent: "flex-end" }}
      >
        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} classNames="mr-2">
            <DatePicker
            slotProps={{ textField: { size: "small" ,fullWidth: true } }}
              label="From"
              value={from ? new Date(from) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setFrom(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </div>

        <div style={{ marginRight: "10px" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
            slotProps={{ textField: { size: "small" ,fullWidth: true } }}
              label="To"
              value={to ? new Date(to) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setTo(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
              style={{ marginRight: "20px" }}
            />
          </LocalizationProvider>
        </div>

        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
            marginLeft: "10px",
          }}
          size="small"
          id="combo-box-demo"
          options={[{ key: "poNumber", label: "PO Number" }]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
          }}
        />
        <TextField
          id="outlined-basic"
          label={"enter " + searchBy}
          size="small"
          variant="outlined"
          onChange={(e) => setSearchKey(e.target.value)}
          sx={{marginLeft:"10px"}}
        />
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          style={{ marginLeft: "10px" }}
          onClick={() => {
            search();
          }}
        >
          <SearchIcon />
        </Button>
      </div>
      <br />
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Sr.No</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Quotation Number</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Quotation Date
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>PO Number</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>PO Date</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Amount</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {slicedData.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell style={{ minWidth: 130 }}>
                    {row.quotationNumber}
                  </TableCell>
                  <TableCell style={{ minWidth: 130 }}>
                    {moment(row.quotationDate).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell style={{ minWidth: 130 }}>
                    {row.poNumber}
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }}>
                    {moment(row.poDate).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }}>{row.amount}</TableCell>
                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                    <Tooltip title="Edit PO" placement="top-start">
                        <Button
                          component={Link}
                          to={`/poAcceptance/edit/${row.id}`}
                        >
                          <EditIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="View File" placement="top-start">
                        <Button
                          component={Link}
                          to={row.poFileUrl}
                          target="_blank"
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Download File" placement="top-start">
                        <Button
                        onClick={() => handleDownload(row)}
                        >
                      <DownloadIcon/>
                        </Button>
                      </Tooltip>
                      {showComponent("delete") && (
                        <Tooltip title="Delete PO" placement="top-start">
                          <Button
                            onClick={(e) => {
                              if (
                                window.confirm(
                                  "Really want to delete PO?"
                                )
                              ) {
                                submitSiteDelete(row.id);
                              }
                            }}
                          >
                            <DeleteIcon style={{ color: "#dc3545" }} />
                          </Button>
                        </Tooltip>
                      )}
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={opTableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default Po_acceptanceList;
